ul.content-list {
  list-style:     none;
  margin:         0;
  padding-left:   1.4em;

  li:before {
    content:        "\2714\0020";
    display:        block;
    height:         0;
    left:           -1.4em;
    position:       relative;
    width:          0;
  }
}


.btn-padded {
  min-width: 250px;
}