@include media-breakpoint-down(md) {
  html { font-size: 14px; }
}

@include media-breakpoint-down(xs) {
  html { font-size: 14px; }
  h1 { font-size: 2.25rem; }
  h2 { font-size: 1.66rem; }
  h3 { font-size: 1.33rem; }
  .section h3 { font-size: 1.33rem; }
  .jumbotron p { font-size: 1.33rem; }
}