.main-content {
  padding-top: 3.5rem;
}

.navbar {
  font-size: 1rem;

  svg { // background-color for svg logo
    fill: white;
    transition: 0.3s ease-in;
  }

   @extend .navbar-dark;

  background: linear-gradient(90deg, #A5203F 0%, #FF6400 34%, #FFA100 69%, #FFC800 100%);

//   li { line-height: 1; }

  .navbar-brand
  {
      padding-top: 0px;
  }

  .nav-link
  {
      padding-top: 0.66rem;
      padding-bottom: 0.34rem;
  }
}


@include media-breakpoint-down(sm) {
  .dropdown-menu {
    background: none;
    border: none;

    .dropdown-item {
      color: $navbar-dark-color;

      &:hover, &:focus {
        background: none;
        color: $navbar-dark-hover-color;
      }
    }
  }
}


.for_loggedout { display: list-item; }
.for_loggedin { display: none; }

.loggedin .for_loggedout { display: none; }
.loggedin .for_loggedin { display: list-item; }


.jumbo {
  margin-bottom: 0;
  position: relative;
  padding: 0;

  .container {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  h1 {
    text-transform: uppercase;
  }

  p {
    font-size: 1.75rem;
  }

  .big-sun {
    padding-top: 12px;
    text-align: center;

    width: 256px;
    height: 256px;
    background: linear-gradient(to top, #A5203F, #A5203F, #CF453C, #FF6400, #F78D26, #FBAB17, #FDBF0C, #FDBF0C);
    background-size: 100% 800%;
    border-radius: 100%;
    animation: 6s rise forwards;
    animation-delay: 1s;

    position: absolute;
    top: 110%;
    background-position: 50% 100%;


    .label {
      font-family: $headings-font-family;
      font-size: 2rem;
      color: $white;
    }
  }
}

@keyframes rise {
     0% { top: 110%; background-position: 50% 100%; }
   100% { top: 0%;   background-position: 50% 0%; }
}


.section {
  padding: 4rem 0;

  * {
    word-break: break-word
  }

  h2 {
    text-transform: uppercase;
    margin-bottom: 2rem;
  }

  h3 {
    color: $orange !important;
    font-family: $font-family-base;
  }

  h4 {
    text-transform: uppercase;
  }
}

@media (min-width:576px) {
  .section {
    padding: 6rem 0;
  }
}


.secondary {
  p {
    text-align: justify;
  }

  h2, h3  {
    margin-top: 3rem;
  }

  h4, h5  {
    margin-top: 1.5rem;
  }

  h1, h2, h3, h4, h5  {
    margin-bottom: 1rem;
  }
}

.MathJax_Display {
  margin: 5rem 0 !important;
}


.square-ratio {
  padding-top: 100%;
  position: relative;

  .wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
  }
}

.sun {
  height: 100%;
  width: 100%;
  border-radius: 100%;
  padding-top: 4px;
  text-align: center;

  .label {
    font-family: $headings-font-family;
    color: $white;
  }
}


.clipboard {
  border: #C6C9CA 1.25rem solid;
  border-radius: 1.25rem;
  position: relative;

  .clip {
    position: absolute;
    left: 25%;
    right: 25%;
    top: -2.75rem;

    img { width: 100%; }
  }

  .paper { background-color: white; }
}


.contact {
  background-image: url(/assets/images/contact_bg.png);
  background-image: image-set(
    url(/assets/images/contact_bg.png) 1x,
    url(/assets/images/contact_bg@2x.png) 2x
  );
  background-size: cover;
  background-position: center;
}


footer {
  background-color: #39414d;
  color: #fff;
  padding-bottom: 1rem;
  padding-top: 4rem;
  font-size: .875rem;

  h1, h2, h3, h4, h5, h6 {
    color: #fff
  }

  .logo {
    border-bottom: 2px solid #fff;
    padding: 0 0 1rem;
    
    img {
      max-width: 130px
    }
  }

  .blurb {
    margin-bottom: 2rem;

    p {
      font-size: .75rem;
    }
  }

  .content {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .nav-main {
    padding-top: 0;

    h3 {
      font-size: 1rem;
      margin-bottom: 1rem
    }

    ul {
      list-style: none;
      padding: 0;

      & > li {
        margin-bottom: 1rem;
        font-size: .875rem;

        & > a {
          color: #fff;
          text-transform: none
        }
      }
    }

    & > div {
      margin-bottom: 1.5rem;
    }
  }

  .nav-secondary {
    font-size: .75rem;
  
    a {
      color: #fff!important;
      text-transform: none!important
    }
  
    p {
      margin-bottom: 0
    }
  
    div {
      margin-bottom: 1rem
    }
  }
    
  .nav-social-media {
    h3 {
      margin-bottom:1.5rem;
      font-size:.875rem
    }

    a {
      margin-right: 1.5rem;
    }

    svg.socialMedia {
      .st0 {
        fill:#fff;
      }

      &:hover .st0 {
        fill:#50bde1;
      }
    }
  }

  .disclaimer {
    margin-bottom: 1rem;
  
    .wrapper {
      border-top: 2px solid #565c67;
      padding: 0 0 1rem;
    }
  
    p {
      color: #c6c9ca;
      font-size: .75rem;
  
      &:first-of-type {
        margin-top: 1.5rem;
        margin-bottom: .3rem;
        font-weight: 700
      }
  
      &:last-of-type {
        margin-top: 1rem;
      }
  
      a {
        color: #fff;
        font-size: .75rem;
        text-transform: none
      }
    }
  }
}

@media (min-width: 768px) {
  footer {
    padding-bottom: 3rem;
    padding-top: 6rem;

    .blurb,
    .nav-main > div {
      margin-bottom: 0;
    }

    .content {
      margin-bottom: 4rem;
    }

    .nav-secondary {
      div {
        margin-bottom: 0;
      }
    }
  }
}

@media (min-width: 992px) {
  footer {
    .blurb {
      padding-right: 2rem
    }
  }
}

@media (min-width: 1200px) {
  footer {
    .blurb {
      padding-right: 7rem
    }
  }
}


.consent {
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 0.75rem 1rem;
  background-color: #39414d;
  color: $white;
  font-size: 1rem;
  opacity: 0;
  transition: 1s opacity ease-in-out;

  &.display {
    opacity: 1;
  }

  .text { color: #c5c5c5; }
}
