body,
html {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
}

.page {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch
}

.main-content {
  flex-grow: 1;
  height: 100%
}

.main-content,
footer,
header {
  flex-shrink: 0
}
