// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white:    #fff !default;
$gray-100: #f4f4f4 !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #39414d !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #ff6400 !default;
$yellow:  #ffc107 !default;
$green:   #00A562 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "blue":       $blue,
    "indigo":     $indigo,
    "purple":     $purple,
    "pink":       $pink,
    "red":        $red,
    "orange":     $orange,
    "yellow":     $yellow,
    "green":      $green,
    "teal":       $teal,
    "cyan":       $cyan,
    "white":      $white,
    "gray-light": $gray-100,
    "gray":       $gray-600,
    "gray-dark":  $gray-800
  ),
  $colors
);

$primary:       $orange !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark,

    "white":      $white,
    "orange":     $orange
  ),
  $theme-colors
);

$body-color:                  #39414d !default;

$btn-border-radius:           100vh !default;
$input-btn-padding-x:         1.5rem !default;


// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:      "ArialFallback", Arial, Helvetica, sans-serif !default;
$font-family-base:            $font-family-sans-serif !default;

$font-size-base:              1.25rem;
$input-btn-font-size:         1rem;

$h1-font-size:                $font-size-base * 2;
$h2-font-size:                $font-size-base * 1.6;
$h3-font-size:                $font-size-base * 1.3;
$h4-font-size:                $font-size-base * 1 !default;
$h5-font-size:                $font-size-base * 1 !default;
$h6-font-size:                $font-size-base !default;

$headings-font-family:        "ArialBlackFallback", "Arial Black", "Helvetica Black", sans-serif !default;

$headings-color:              #39414d !default;

$navbar-brand-padding-y:            0.25rem;
$navbar-brand-font-size:            1rem;
$navbar-dark-color:                 rgba($white, .8);
$navbar-dark-hover-color:           $white;
$navbar-dark-active-color:          $white;
$navbar-dark-disabled-color:        rgba($white, .5);
$navbar-dark-toggler-icon-bg:       str-replace(url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"), "#", "%23") !default;
$navbar-dark-toggler-border-color:  none;

$navbar-nav-link-padding-x:         .75rem !default;
//$dropdown-item-padding-y:           .75rem !default;
$dropdown-font-size:                1rem;
$enable-caret:                true;
$caret-spacing:               8px;