@font-face {
  font-family: "ArialBlackFallback";
  src: url("/assets/fonts/ariblk.eot"); /* IE9 Compat Modes */
  src: url("/assets/fonts/ariblk.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("/assets/fonts/ariblk.otf") format("opentype"), /* Open Type Font */
    url("/assets/fonts/ariblk.svg") format("svg"), /* Legacy iOS */
    url("/assets/fonts/ariblk.ttf") format("truetype"), /* Safari, Android, iOS */
    url("/assets/fonts/ariblk.woff") format("woff"), /* Modern Browsers */
    url("/assets/fonts/ariblk.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ArialFallback";
  src: url("/assets/fonts/arial.eot"); /* IE9 Compat Modes */
  src: url("/assets/fonts/arial.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("/assets/fonts/arial.otf") format("opentype"), /* Open Type Font */
    url("/assets/fonts/arial.svg") format("svg"), /* Legacy iOS */
    url("/assets/fonts/arial.ttf") format("truetype"), /* Safari, Android, iOS */
    url("/assets/fonts/arial.woff") format("woff"), /* Modern Browsers */
    url("/assets/fonts/arial.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}